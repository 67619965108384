:root {
  --amplify-primary-color: #eb5b26;
  --amplify-primary-tint: #eb5b26;
  --amplify-primary-shade: #e0573e;
}

amplify-authenticator {
  --container-height: 100%;
}

body {
  background-color: black;
}
